body[data-app="checklist"] {
    background-color: #333333 !important;
    min-height:100vh;
    padding:0;
    margin:0;
}

.checklist-container {
    background-color: #333333;
    font-size:16px;
    /* colors from visual studio default theme */
    --vsNumber: #B5CEA8;
    --vsVariable: #9CDCFE;
    --vsFunction: #DCDCAA;
    --vsDefault: #D4D4D4;
    --vsString: #CE9178;
    --vsBorder: #585858;
    --vsFont: 'Consolas', 'Courier New', Courier, monospace;

    --vsFaded: #8C8C8C;
    --vsHiBg: #264F78;

    font-family: var(--vsFont);
    color: var(--vsDefault);
    margin:0;
    padding-top:16px;
    padding-bottom:16px;
}

.rxp-main {
    margin:0 auto;
    max-width: 1000px;
    background-color: #1E1E1E;
    padding:8px;
    box-shadow: 0 0 4px #000;
}

.rxp-faded { color: var(--vsFaded); }
.rxp-flex-vcenter-only { display: flex; align-items: center; }
.rxp-flex-center { display: flex; align-items: center; justify-content: center; }
.rxp-flex-between { display: flex; align-items: center; justify-content: space-between; }
.rxp-flex { display: flex; }
.rxp-padded { padding: 4px; }
.rxp-arrow-right { font-size:1.2em; color: var(--vsFaded); }

.rxp-hi { background-color: var(--vsHiBg) }

.rxp-border-bar {
    width: 100%;
    height: 0;
    margin-right:0;
    margin-left:0;
    margin-top: 2px;
    margin-bottom: 0;
    border-bottom: 1px solid var(--vsBorder);
}
.rxp-input {
    background-color: #3C3C3C;
    outline:0;
    border: 1px solid var(--vsBorder);
    color: var(--vsDefault);
    font-family: var(--vsFont);
    font-size: 14px;
    width:200px;
    padding: 2px 4px;
}

.rxp-checkbox {
    appearance: none;
	background-color: #3c3c3c;
	border: 1px solid var(--vsBorder);
	padding: 5px;
	border-radius: 2px;
	display: inline-block;
	position: relative;
}
.rxp-checkbox:checked:after {
	content: '\2714';
	font-size: 12px;
	position: absolute;
	top: -4px;
	left: 1px;
	color: var(--vsDefault);
}

.rxp-button {
    background-color: #3C3C3C;
    outline:0;
    border: 1px solid var(--vsBorder);
    color: var(--vsFaded);
    font-family: var(--vsFont);
    font-size: 14px;
    padding: 2px 4px;
    cursor: pointer;
}
.rxp-button:hover { color: var(--vsDefault); background-color: #555555; }
.rxp-page-button {
    background-color: #3C3C3C;
    outline:0;
    border: 1px solid var(--vsBorder);
    color: var(--vsFaded);
    font-family: var(--vsFont);
    font-size: 14px;
    padding: 2px 4px;
    cursor: pointer;
}
.rxp-page-button-active {
    background-color: #505050;
    color: var(--vsDefault);
}
.rxp-button-group { margin-left:8px; }


.rxp-print-section-header {
    margin-top:4px;
    margin-bottom:4px;
}
.rxp-print-list-type { color: #000; font-weight:bold; }
.rxp-print-list-dash { color: #555; padding: 0 8px; }
.rxp-print-list-name { color: #000; }
.rxp-print-list-desc { color: #bbb; padding-top:4px; }


.rxp-label, .rxp-title { font-size: 16px; }
.rxp-label { color: var(--vsFaded); }
.rxp-title { margin-left:4px; color: var(--vsDefault); }
.rxp-small-label { font-size: 12px; color: var(--vsFaded); }

.rxp-table {
    margin-top: 8px;
    width: 100%;
    border: 1px solid var(--vsBorder);
    font-family: var(--vsFont);
    border-collapse: collapse;
}

.rxp-table td {
    border: 1px solid var(--vsBorder);
    vertical-align: top;
    margin: 0;
}



.rxp-root {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Consolas', 'Courier New', Courier, monospace;
}

.rxp-operator {
    /* operators (and,or,+,-,<,>, etc) */
    padding-left: 1ch;
    padding-right: 1ch;
    color:var(--vsDefault);
}
.rxp-unary-operator {
    /* the minus sign in "-9 > 10" for example */
    color: var(--vsDefault);
}
.rxp-paren { color: var(--vsDefault); }
.rxp-array-bracket { color:var(--vsDefault); }
.rxp-function-name { color: var(--vsFunction); }
.rxp-numeric-constant { color: var(--vsNumber); }
.rxp-string-constant { color: var(--vsString); }
.rxp-quote-separator { color: var(--vsString); }
.rxp-model-variable { color: var(--vsVariable); }
.rxp-comma-separator { padding-right: 1ch; color: var(--vsDefault); }
.rxp-warn { background-color: #ee4433; /* alert them expresssion is invalid */ }



.rxp-print-only { display:none; }
@media print {
    body { background-color:#FFFFFF; color:#000000; margin:0; padding:0; }
    .rxp-print-only { display:block; }
    .rxp-print-hide { display:none; }
    .rxp-hi { background-color:#FFFFFF; }
    .rxp-main { 
        max-width: none;
        box-shadow: none;
        border:0;
        padding:0;
        margin-top: 0;
        --vsNumber: #000;
        --vsVariable: #000;
        --vsFunction: #000;
        --vsDefault: #000;
        --vsString: #000;
        --vsBorder: #bbb;
        --vsFaded: #999;
    }
}

.rxp-tabs {
    display:flex;
    margin-left:0px;
    margin-bottom:8px;
}
.rxp-active-tab {
    color:#ffffff;
    margin-right:8px;
    padding:2px;
    border-bottom:2px solid var(--vsBorder);
}
.rxp-tab {
    color:var(--vsBorder);
    cursor:pointer;
    margin-right:8px;
    padding:2px;
    border-bottom:2px solid transparent;
}
.rxp-tab:hover {
    color: #ee8;
    border-bottom:2px solid #444;
}
.rxp-checklist-name {
    padding-right:4px;
}

.rxp-dropdown {
    background-color: transparent;
    border:1px solid var(--vsBorder);
    outline: none;
    padding:0;
    margin:0;
    font-size:16px;
    font-family: var(--vsFont);
    color: var(--vsDefault);
    box-shadow: none !important;
    
    
}
.rxp-dropdown option,
.rxp-dropdown option:hover,
.rxp-dropdown option:focus,
.rxp-dropdown option:active,
.rxp-dropdown option:checked {
    outline: none;
    border:0;
    background-color: #333;
    font-family: var(--vsFont);
    color: var(--vsDefault);
    box-shadow: none !important;
}